import React, {Component} from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import LandingRoutes from './common/LandingRoutes';
import BaseLayout from './common/BaseLayout';
import './App.css';

@inject('userStore')
@withRouter
@observer
class App extends Component {
  render(){
    if (this.props.userStore.token !== '') {
      return (
        <BaseLayout/>
      );
    } else {
      return (
        <LandingRoutes/>
      )
    }
  }
}
export default App;
