import React, {Component} from 'react';
import LocationsQuickview from './LocationsQuickview';
import LocationLegalModal from './LocationLegalModal';
import LocationUserModal from './LocationUserModal';
import Modal from 'react-bootstrap4-modal';
import BootstrapTable from 'react-bootstrap-table-next';
import {Spinner} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
} from 'react-places-autocomplete';
import { connectionGet, connectionPost, connectionPut, connectionDelete } from '../utils/connection';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {withRouter} from "react-router-dom";
import { inject, observer } from 'mobx-react';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import moment from 'moment';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

@inject('userStore')
@observer
class Locations extends Component{
  constructor(props){
    super(props);
    this.state = {
      modalVisible:false,
      quickviewVisible:false,
      seatingModalVisible:false,
      seatingZoneModalVisible:false,
      legalModalVisible:false,
      hoursModalVisible:false,
      userModalVisible:false,
      reservationModalVisible:false,
      shareModalVisible:false,
      loading:true,
      item_list:[],
      selectedItem:{id:null, name:'', address1:'', address2:'', city:'', region:'', postal:'', website:'', phone:'', email:'', lat:'', lng:'', g_place_id:''},
      role_list:[],
      sys_share_type_list:[]
    }
  }

  componentDidMount(){
    this.loadItems();
  }

  loadItems = () => {
    this.setState({
      loading:true
    }, ()=>{
      connectionGet('business/' + this.props.userStore.business_hash_id + '/locations', {get_min:1}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          this.setState({
            loading:false,
            item_list:res.data.item_list
          })
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    });
  }

  addItem = (e) => {
    e.preventDefault();
    if (this.state.selectedItem.hash_id){
      let tmp = Object.assign({}, this.state.selectedItem);
      connectionPut('business/' + this.props.userStore.business_hash_id + '/locations/' + this.state.selectedItem.hash_id, {location:tmp}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({
            item_list:res.data.item_list,
            selectedItem:{id:null, name:'', address1:'', address2:'', city:'', region:'', postal:'', website:'', phone:'', email:'', lat:'', lng:'', g_place_id:''}
          },()=>this.backgroundClick())
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    } else {
      connectionPost('business/' + this.props.userStore.business_hash_id + '/locations', {location:this.state.selectedItem}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({
            item_list:res.data.item_list,
            selectedItem:{id:null, name:'', address1:'', address2:'', city:'', region:'', postal:'', website:'', phone:'', email:'', lat:'', lng:'', g_place_id:''}
          },()=>this.backgroundClick)
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    }
  }

  addUser = (user) => {
    if (user.id === 0 && user.user.email !== '' && user.user.password !== ''){
      connectionPost('locations/' + this.state.selectedItem.hash_id + '/users', {location_user:user}, {username: this.props.userStore.token}
      ).then((res) => {
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({
            selectedItem:res.data.location
          })
          this.loadItems();
        } else {
          toast.error(res.data.message, toastParams);
        }
      }).catch((error) => {
        console.log(error);
        return error;
      });
    } else {
      // this is editing an existing user
      if (user.id !== 0){
        connectionPut('locations/' + this.state.selectedItem.hash_id + '/users/' + user.user.hash_id, {location_user:user}, {username: this.props.userStore.token}
        ).then((res) => {
          if (res.data.errors === 0){
            toast.success(res.data.message, toastParams);
            this.setState({
              selectedItem:res.data.location
            })
            this.loadItems();
          } else {
            toast.error(res.data.message, toastParams);
          }
        }).catch((error) => {
          console.log(error);
          return error;
        });
      }
    }
  }

  backgroundClick = () => {
    this.setState({
      quickviewVisible:false,
      modalVisible:false,
      seatingModalVisible:false,
      seatingZoneModalVisible:false,
      legalModalVisible:false,
      hoursModalVisible:false,
      userModalVisible:false,
      reservationModalVisible:false,
      shareModalVisible:false
    })
  }

  handleInputChange = (e) => {
    let updated_obj = Object.assign({}, this.state.selectedItem);
    let value = e.target.value;
    if (e.target.type === 'checkbox'){
      if (e.target.checked){
        value = 1;
      } else {
        value = 0;
      }
    }
    if (e.target.name === 'ready_message'){
      if (value.length > 150){
        alert('Ready message must be less than 150 characters');
        return false;
      }
    }
    if (e.target.name === 'schedule_enabled' && e.target.checked){
      alert('Be sure to create a weekly schedule by clicking on the Hours Action.');
    }
    updated_obj[e.target.name] = value;
    this.setState({selectedItem:updated_obj});
  }

  handleAddressChange = (address) => {
    this.handleInputChangeDirect("name", address);
  }

  handleInputChangeDirect = (targetName, value) => {
    let updated_obj = Object.assign({}, this.state.selectedItem);
    updated_obj[targetName] = value;
    this.setState({selectedItem:updated_obj});
  }

  getPlace = placeId => {
    var service = new window.google.maps.places.PlacesService(document.createElement('div'));
    return new Promise((resolve, reject) => {
      service.getDetails({'placeId':placeId}, (results, status)=>{
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
          reject(status);
        }
        resolve(results);
      });
    });
  };

  openModal = (location) => {
    this.backgroundClick();
    this.setState({
      modalVisible:true,
      selectedItem:location
    })
  }

  openLegalModal = (location) => {
    this.backgroundClick();
    this.setState({
      legalModalVisible:true,
      selectedItem:location
    })
  }

  openSeatingModal = (location) => {
    this.backgroundClick();
    this.setState({
      seatingModalVisible:true,
      selectedItem:location
    })
  }

  openHoursModal = (location) => {
    this.backgroundClick();
    this.setState({
      hoursModalVisible:true,
      selectedItem:location
    })
  }

  openUsersModal = (location) => {
    this.backgroundClick();
    this.setState({
      userModalVisible:true,
      selectedItem:location
    })
  }

  openQuickview = (location) => {
    this.backgroundClick();
    if (location === null){
      location = {id:null, name:'', address1:'', address2:'', city:'', region:'', postal:'', website:'', phone:'', email:'', lat:'', lng:'', g_place_id:''}
    }
    this.setState({
      quickviewVisible:true,
      selectedItem:location
    })
  }

  openReservationModal = (location) => {
    this.backgroundClick();
    this.setState({
      reservationModalVisible:true,
      selectedItem:location
    })
  }

  openShareModal = (location) => {
    this.backgroundClick();
    this.setState({
      shareModalVisible:true,
      selectedItem:location
    })
  }

  openSeatingZoneModal = (location) => {
    this.backgroundClick();
    this.setState({
      seatingZoneModalVisible:true,
      selectedItem:location
    })
  }

  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        this.getPlace(results[0].place_id).then(results => {
          this.handleInputChangeDirect("name", results.name);
          this.handleInputChangeDirect("g_place_id", results.place_id);
          this.handleInputChangeDirect("lat", results.geometry.location.lat());
          this.handleInputChangeDirect("lng", results.geometry.location.lng());
          this.handleInputChangeDirect("phone", results.formatted_phone_number);
          this.handleInputChangeDirect("website", results.website);
          for (var i = 0; i < results.address_components.length; i++) {
            var addressType = results.address_components[i].types[0];
            if (addressType == 'street_number'){
              this.handleInputChangeDirect("address1", results.address_components[i].long_name);

              // this.formApi.setValue("address", results.address_components[i].long_name);
            }
            if (addressType == 'route'){
              this.handleInputChangeDirect("address1", this.state.selectedItem.address1 + " " + results.address_components[i].short_name);
              // this.formApi.setValue("address", this.formApi.getValue("address") + ' ' + results.address_components[i].short_name);
            }
            if (addressType == 'locality'){
              this.handleInputChangeDirect("city", results.address_components[i].long_name);
              // this.formApi.setValue("city", results.address_components[i].long_name);
            }
            if (addressType == 'administrative_area_level_1'){
              this.handleInputChangeDirect("region", results.address_components[i].short_name);
              // var state = results.address_components[i].short_name
              // this.formApi.setValue("state", state);
            }
            if (addressType == 'postal_code'){
              this.handleInputChangeDirect("postal", results.address_components[i].short_name);
              // this.formApi.setValue("zipcode", results.address_components[i].short_name);
              // this.setState({
              //     errors:this.formApi.getState().errors
              // })
            }
          }
        });
      });
        // .then(latLng => s('Success', latLng))
        // .catch(error => console.error('Error', error));
  };

  render(){
    const {loading, item_list, quickviewVisible, modalVisible, seatingModalVisible, seatingZoneModalVisible, legalModalVisible, selectedItem, hoursModalVisible, userModalVisible, reservationModalVisible, role_list, shareModalVisible, sys_share_type_list} = this.state;
    const columns = [{
      dataField: 'name',
      text: 'Name',
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div className="text-truncate">
            {row.name}
          </div>
        );
      }
    }, {
      dataField: 'address1',
      text: 'Address',
      headerClasses: 'd-none d-sm-table-cell',
      classes: 'd-none d-sm-table-cell',
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div className="text-truncate">
            {row.address1}
          </div>
        );
      }
    }, {
      dataField: 'current_count',
      text: 'Current',
      headerClasses: 'd-none d-sm-table-cell',
      classes: 'd-none d-sm-table-cell',
      sort: false
    }, {
      dataField: 'capacity',
      text: 'Max',
      headerClasses: 'd-none d-sm-table-cell',
      classes: 'd-none d-sm-table-cell',
      sort: false
    },{
      dataField: 'Actions',
      isDummyField: true,
      text: 'Actions',
      formatter: (cellContent, row) => {
        if (this.props.userStore.location_user_list.length > 0){
          return (
            <a href={"/location-waitlist/" +  row.hash_id} className="btn btn-default">Waitlist</a>
          );
        } else {
          return (
            <div className="dropdown dropdown-default full-width">
              <button aria-label="" className="btn dropdown-toggle full-width text-truncate d-inline-block" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="semi-bold">Actions</span>
              </button>
              <div className="dropdown-menu">
                <a href={"/location-settings/" + row.hash_id} className="dropdown-item" title="Edit Location"><i className="pg-icon">settings</i> Settings</a>
              </div>
            </div>
          );
        }
      }
    }];
    return (
      <React.Fragment>
      <Modal visible={quickviewVisible} className="modal slide-right" dialogClassName="modal-dialog" fade={true} onClickBackdrop={this.backgroundClick}>
        <LocationsQuickview backgroundClickHandler={this.backgroundClick} handleInputChange={this.handleInputChange} handleSelect={this.handleSelect} handleAddressChange={this.handleAddressChange} item={selectedItem} submitHandler={this.addItem}/>
      </Modal>
      <Modal visible={legalModalVisible} className="modal slide-up" dialogClassName="modal-dialog modal-xl" fade={true} onClickBackdrop={this.backgroundClick}>
        {selectedItem.hash_id !== '' ?
        <LocationLegalModal backgroundClickHandler={this.backgroundClick} location={selectedItem} addItem={this.addItem} handleInputChange={this.handleInputChangeDirect}/>
        : <div></div>}
      </Modal>
      <Modal visible={userModalVisible} className="modal slide-up" dialogClassName="modal-dialog modal-xl" fade={true} onClickBackdrop={this.backgroundClick}>
        {selectedItem.hash_id !== '' ?
        <LocationUserModal backgroundClickHandler={this.backgroundClick} location={selectedItem} addUser={this.addUser} role_list={role_list}/>
        : <div></div>}
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable={false}
        pauseOnHover={false}
      />
      <div className="no-padding container-fixed-lg bg-white">
        <div className="container">
          <div className="card card-transparent">
            <div className="card-header p-l-0">
              <div className="card-title">
                <h4>Locations</h4>
              </div>
              <div className="card-controls">
                <ul>
                  <li><a href="#" className="card-close" data-toggle="close" onClick={()=>this.openQuickview(null)}><i className="pg-icon">plus</i></a></li>
                  <li><a href="#" className="card-refresh" title="Refresh" data-toggle="refresh" onClick={this.loadItems}><i className="card-icon card-icon-refresh"></i></a></li>
                </ul>
              </div>
            </div>
            <div className="card-body p-l-0 p-r-0 p-b-200">
              <div className="table-responsive" style={{minHeight:'550px'}}>
              {loading ?
                <div className="card-body">
                  <h4><Spinner color="primary"/> Loading...</h4>
                </div>
              :
                <BootstrapTable keyField='id' classes='table-condensed table table-striped table-hover table-bordered' remote={true} data={item_list} columns={columns} striped hover headerClasses="thead-light"/>
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
    )
  }
}

export default Locations;

// <li><a href="#" className="card-close" data-toggle="close" onClick={()=>this.openQuickview(null)}><i className="pg-icon">plus</i></a></li>
// <button className="btn" onClick={()=>this.openModal(row)} title="Employees"><i className="pg-icon">users</i></button>
// <a href="#" className="dropdown-item" onClick={()=>this.openHoursModal(row)} title="Available Hours"><i className="pg-icon">clock</i> Hours</a>
// <a href="#" className="dropdown-item" onClick={()=>this.openReservationModal(row)} title="Reservation Settings"><i className="pg-icon">settings</i> Res. Settings</a>
