import axios from 'axios';

const baseUrl = '/api/';

export const connectionGet = (url, params, auth) =>
  axios({"url": baseUrl + url, method: "GET", auth: auth, params});

export const connectionPut = (url, params, auth) =>
  axios.put(baseUrl + url, params,{auth: auth});

export const connectionPost = (url, params, auth) =>
  axios.post(baseUrl + url, params,{auth: auth});

export const connectionFilePost = (url, params, auth) => {
  const config = {
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    auth:auth
  }
  return axios.post(baseUrl + url, params, config);
}

export const connectionDelete = (url, params, auth) =>
  axios({"url": baseUrl + url, method: "DELETE", auth: auth, params});
  // axios.delete(baseUrl + url, params,{auth: auth});
