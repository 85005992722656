import React, {Component} from 'react';
import {inject} from 'mobx-react';
import WYSIWYGBlock from '../common/WYSIWYGBlock';

@inject('userStore')
class TermsQuickview extends Component{
  constructor(props){
    super(props);
    this.state = {
    }
  }

  render(){
    const {item, handleInputChange, backgroundClickHandler, submitHandler, handleInputChangeDirect} = this.props;
    const isInvalid = item.name === '' && item.terms === '';
    return (
      <div className="modal-content-wrapper">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="m-t-0"><i className="pg-icon">alert</i> Offer Terms</h3>
          </div>
          <div className="modal-body">
            <form role="form" id="form-register">
              <div className="form-group-attached">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-group-default">
                      <label>Name</label>
                      <input type="text" name="name" placeholder="" className="form-control" value={item.name} onChange={handleInputChange} required/>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <WYSIWYGBlock name={'terms'} value={item.terms} handleInputChange={handleInputChangeDirect} title={'Offer Legal Terms'}/>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-group-default">
                      <label>Versioning</label>
                      <input type="text" name="versioning" placeholder="" className="form-control" value={item.versioning} onChange={handleInputChange}/>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="row m-t-20">
              <div className="col-md-6 offset-md-6">
                <div className="btn-group pull-right">
                  <button aria-label="" type="button" className="btn btn-default" onClick={backgroundClickHandler}>Cancel</button>
                  <button aria-label="" type="submit" className={isInvalid ? "btn btn-primary disabled" : "btn btn-primary"} onClick={isInvalid ? ()=>{return false;} : submitHandler}>Save Terms</button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    )
  }
}

export default TermsQuickview;
