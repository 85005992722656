import React, {Component} from 'react';
import {Spinner} from 'reactstrap';
import {inject} from 'mobx-react';
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
} from 'react-places-autocomplete';

@inject('userStore')
class LocationsQuickview extends Component{
  constructor(props){
    super(props);
    this.state = {
    }
  }

  render(){
    const {item, handleInputChange, backgroundClickHandler, submitHandler, handleSelect, handleAddressChange} = this.props;
    const isInvalid = item.name === '' && item.address1 === '' && item.city === '' && item.region === '' && item.postal === '';
    return (
      <React.Fragment className="modal-content-wrapper">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="m-t-0"><i className="pg-icon">alert</i> Information</h3>
          </div>
          <div className="modal-body">
            <form role="form" id="form-register">
              <div className="form-group-attached">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-group-default">
                      <label>Location Name</label>
                      <PlacesAutocomplete value={item.name} onChange={handleAddressChange} onSelect={handleSelect}>
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <React.Fragment>
                          <input
                              {...getInputProps({
                                placeholder: 'Enter Location Name',
                                className: 'form-control',
                              })}
                          />
                          <div className={suggestions.length > 0 ? "autocomplete-dropdown-container" : null}>
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                              return (
                                <div style={{ borderWidth: 0.5, borderColor: '#d6d7da'}}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </React.Fragment>
                      )}
                      </PlacesAutocomplete>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group form-group-default">
                      <label>Address 1</label>
                      <input type="text" name="address1" placeholder="" className="form-control" value={item.address1} onChange={handleInputChange} required/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group form-group-default">
                      <label>Address 2</label>
                      <input type="text" name="address2" placeholder="" className="form-control" value={item.address2} onChange={handleInputChange}/>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group form-group-default">
                      <label>City</label>
                      <input type="text" name="city" placeholder="" className="form-control" value={item.city} onChange={handleInputChange} required/>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group form-group-default">
                      <label>State</label>
                      <input type="text" name="region" placeholder="2 Letter" className="form-control" value={item.region} onChange={handleInputChange} required/>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group form-group-default">
                      <label>Postal Code</label>
                      <input type="text" name="postal" placeholder="Zipcode" className="form-control" value={item.postal} onChange={handleInputChange} required/>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group form-group-default">
                      <label>Website</label>
                      <input type="text" name="website" placeholder="Website URL" className="form-control" value={item.website} onChange={handleInputChange}/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group form-group-default">
                      <label>Phone</label>
                      <input type="text" name="phone" placeholder="Primary Contact" className="form-control" value={item.phone} onChange={handleInputChange} required/>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group form-group-default">
                      <label>Email</label>
                      <input type="text" name="email" placeholder="Email" className="form-control" value={item.email} onChange={handleInputChange}/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group form-group-default">
                      <label>Unique Code</label>
                        <input type="text" maxLength="3" name="location_code" placeholder="Code used to check in" className="form-control" value={item.location_code} onChange={handleInputChange}/>
                    </div>
                  </div>
                </div>
                {this.props.userStore.is_admin === 1 ?
                <div className="row">
                  <div className="form-group form-group-default">
                    <label>Image URL</label>
                    <input type="text" name="img_url" placeholder="" className="form-control" value={item.img_url} onChange={handleInputChange}/>
                  </div>
                </div>
                : null}
              </div>
            </form>
            <div className="row m-t-20">
              <div className="col-md-6 offset-md-6">
                <div className="btn-group pull-right">
                  <button aria-label="" type="button" className="btn btn-default" onClick={backgroundClickHandler}>Cancel</button>
                  <button aria-label="" type="submit" className={isInvalid ? "btn btn-primary disabled" : "btn btn-primary"} onClick={isInvalid ? ()=>{return false;} : submitHandler}>Save Location</button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {item.location_code !== '' ?
                  <React.Fragment>
                  <p className="small-text hint-text m-t-20">This is your unique waitlist URL.  You can place this on your website or share it on social media.</p>
                  <a href={"https://wirl.io/wait/" + item.location_code}>{"https://wirl.io/wait/" + item.location_code}</a>
                  <p className="small-text hint-text m-t-20">This QR Code will allow your guests to quickly scan and open your waitlist with their smartphone.  You could post it at your host station or on your front door.</p>
                  <a href={"/api/get_qrimg/" + item.location_code + "/qr.png"} target="_blank">Download QR Code</a><br/>
                  <img className="full-width" src={"/api/get_qrimg/" + item.location_code + "/qr.png"}/>
                  {item.menu_enabled === 1 ?
                      <React.Fragment>
                      <p className="small-text hint-text m-t-20">This is your unique menu URL.  You can print these for your table tops.</p>
                      <a href={"https://wirl.io/menu/" + item.location_code}>{"https://wirl.io/menu/" + item.location_code}</a>
                      <p className="small-text hint-text m-t-20">This QR Code will allow your guests to quickly scan and open your menu with their smartphone.  You could place them on your tables.</p>
                      <a href={"/api/get_qrimg/" + item.location_code + "/1/qr.png"} target="_blank">Download QR Code</a><br/>
                      <img className="full-width" src={"/api/get_qrimg/" + item.location_code + "/1/qr.png"}/>
                      </React.Fragment>
                  : null}
                  </React.Fragment>
                : null}
              </div>
            </div>            
          </div>
        </div>
    </React.Fragment>
    )
  }
}

export default LocationsQuickview;
