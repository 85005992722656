import firebase from 'firebase/app';
import 'firebase/auth';

const config = {
	apiKey: "AIzaSyCpxrusM72oIn1JbQBXF1TLwa1qvKJobeg",
  authDomain: "instty-eaaaa.firebaseapp.com",
  databaseURL: "https://instty-eaaaa.firebaseio.com",
  projectId: "instty-eaaaa",
  storageBucket: "instty-eaaaa.appspot.com",
  messagingSenderId: "797276490015",
  appId: "1:797276490015:web:451bb862a3fe05b54ac82a",
  measurementId: "G-X5B00Y3J66"
};

if (!firebase.apps.length) {
	firebase.initializeApp(config);
}

const auth = firebase.auth();

export {
  auth
};

export default firebase;
