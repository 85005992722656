import React, {Component} from 'react';
import OffersQuickview from './OffersQuickview';
import Modal from 'react-bootstrap4-modal';
import BootstrapTable from 'react-bootstrap-table-next';
import {Spinner} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { connectionGet, connectionPost, connectionPut, connectionDelete, connectionFilePost } from '../utils/connection';
import {withRouter} from "react-router-dom";
import { inject, observer } from 'mobx-react';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import moment from 'moment';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

@inject('userStore')
@observer
class Offers extends Component{
  constructor(props){
    super(props);
    this.state = {
      quickviewVisible:false,
      loading:true,
      item_list:[],
      term_list:[],
      location_list:[],
      quickview:null,
      category_list:[],
      offer_type_list:[],
      selectedItem:{id:null, title:'', location_id:'', business_term_id:'', details:'', expiration_type:'', expiration_datetime:null, is_unlimited:0, quantity_available:1, publish_type:'', sys_status_id:1, publish_datetime:null, img_url:'', offer_url:'', category_id_list:[], sys_category:null, availability:'', quantity_available:''},
    }
  }

  componentDidMount(){
    this.loadItems();
  }

  loadItems = () => {
    this.setState({
      loading:true
    }, ()=>{
      connectionGet('business/' + this.props.userStore.business_hash_id + '/offers', {}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          this.setState({
            loading:false,
            item_list:res.data.item_list,
            term_list:res.data.term_list,
            location_list:res.data.location_list,
            category_list:res.data.category_list,
            offer_type_list:res.data.offer_type_list
          })
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    });
  }

  actions = (actionType) => {
    if (this.state.selectedItem.hash_id){
      let tmp = Object.assign({}, this.state.selectedItem);
      tmp[actionType] = 1;
      connectionPut('business/' + this.props.userStore.business_hash_id + '/offers/' + this.state.selectedItem.hash_id, {offer:tmp}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    }
  }

  addItem = (e) => {
    e.preventDefault();
    if (this.state.selectedItem.hash_id){
      let tmp = Object.assign({}, this.state.selectedItem);
      tmp.update = 1;
      connectionPut('business/' + this.props.userStore.business_hash_id + '/offers/' + this.state.selectedItem.hash_id, {offer:tmp}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({
            item_list:res.data.item_list,
            selectedItem:{id:null, title:'', location_id:'', business_term_id:'', details:'', expiration_type:'', expiration_datetime:null, is_unlimited:0, quantity_available:1, publish_type:'', sys_status_id:1, publish_datetime:null, img_url:'', offer_url:'', category_id_list:[], sys_category:null, availability:'', quantity_available:''}
          })
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    } else {
      connectionPost('business/' + this.props.userStore.business_hash_id + '/offers', {offer:this.state.selectedItem}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({
            item_list:res.data.item_list,
            selectedItem:{id:null, title:'', location_id:'', business_term_id:'', details:'', expiration_type:'', expiration_datetime:null, is_unlimited:0, quantity_available:1, publish_type:'', sys_status_id:1, publish_datetime:null, img_url:'', offer_url:'', category_id_list:[], sys_category:null, availability:'', quantity_available:''}
          })
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    }
  }

  backgroundClick = () => {
    this.setState({
      quickviewVisible:false,
      quickview:null,
      selectedItem:null
    })
  }

  handleInputChange = (e) => {
    let updated_obj = Object.assign({}, this.state.selectedItem);
    let value = e.target.value;
    if (e.target.name === 'sys_category_cb'){
      // let bIncluded = updated_obj.category_id_list.includes(parseInt(value));
      if (e.target.checked){
        let selectedCategory = this.state.category_list.find(du => du.id === parseInt(value));
        updated_obj.sys_category = selectedCategory;
        console.log(updated_obj.sys_category);
        updated_obj.category_id_list = [];
        updated_obj.category_id_list.push(parseInt(value));
        // add if not included - commented out for radio support - return if multiple categories are allowed
        // if (!bIncluded){
        //   updated_obj.category_id_list.push(parseInt(value));
        // }
      } else {
        // remove if included
        updated_obj.sys_category = null;
        var index = updated_obj.category_id_list.indexOf(parseInt(value));
        if (index !== -1) updated_obj.category_id_list.splice(index, 1);
      }
    } else if (e.target.type === 'checkbox'){
      if (e.target.checked){
        value = 1;
      } else {
        value = 0;
      }
    }
    updated_obj[e.target.name] = value;
    this.setState({selectedItem:updated_obj}, ()=>console.log(this.state.selectedItem));
  }

  handleInputChangeDirect = (targetName, value) => {
    let updated_obj = Object.assign({}, this.state.selectedItem);
    updated_obj[targetName] = value;
    this.setState({selectedItem:updated_obj});
  }

  openQuickview = (offer) => {
    this.backgroundClick();
    if (offer === null){
      offer = {id:null, title:'', location_id:'', business_term_id:'', details:'', expiration_type:'', expiration_datetime:null, is_unlimited:0, quantity_available:1, publish_type:'', sys_status_id:1, publish_datetime:null, img_url:'', offer_url:'', category_id_list:[], sys_category:null, availability:'', quantity_available:''}
    }
    this.setState({
      selectedItem:offer,
      quickviewVisible:true
    });
  }

  fileUpload = (offer_hash_id, files) => {
    var formData = new FormData();
    formData.append('file',files[0]);
    connectionFilePost('offer/' + offer_hash_id + '/upload', formData, {username: this.props.userStore.token}
    ).then((res) => {
      if (res.data.errors === 0){
        toast.success(res.data.message, toastParams);
      } else {
        toast.error(res.data.message, toastParams);
      }
    }).catch((error) => {
        toast.error(error.response.statusText, toastParams);
    });
  }

  renderHtml = (content) => {
    return {__html: content};
  }

  render(){
    const {loading, selectedItem, item_list, quickviewVisible, term_list, location_list} = this.state;
    const columns = [{
      dataField: 'title',
      text: 'Title',
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div className="text-truncate">
            {row.title}
          </div>
        );
      }
    }, {
      dataField: 'location_name',
      text: 'Location',
      isDummyField:true,
      headerClasses: 'd-none d-sm-table-cell',
      classes: 'd-none d-sm-table-cell',
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div className="text-truncate">
            {row.location.name}<br/>
            {row.location.address1}
          </div>
        );
      }
    }, {
      dataField: 'details',
      text: 'Details',
      headerClasses: 'd-none d-sm-table-cell',
      classes: 'd-none d-sm-table-cell',
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <div className="text-truncate" style={{wordWrap:'break-word'}} dangerouslySetInnerHTML={this.renderHtml(row.details)}/>
        );
      }
    },{
      dataField: 'Actions',
      isDummyField: true,
      text: 'Actions',
      formatter: (cellContent, row) => {
        return (
          <div className="dropdown dropdown-default full-width">
            <button aria-label="" className="btn dropdown-toggle full-width text-truncate d-inline-block" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span className="semi-bold">Actions</span>
            </button>
            <div className="dropdown-menu">
              <a className="dropdown-item" title="Edit" onClick={()=>this.openQuickview(row)}><i className="pg-icon">pencil</i> Edit</a>
            </div>
          </div>
        );
      }
    }];
    return (
      <React.Fragment>
      <Modal visible={quickviewVisible} className="modal slide-right" dialogClassName="modal-dialog" fade={true} onClickBackdrop={this.backgroundClick}>
        {this.state.quickviewVisible ? <OffersQuickview backgroundClickHandler={this.backgroundClick} handleInputChange={this.handleInputChange} handleInputChangeDirect={this.handleInputChangeDirect} item={this.state.selectedItem} submitHandler={this.addItem} term_list={this.state.term_list} location_list={this.state.location_list} category_list={this.state.category_list} fileUploadHandler={this.fileUpload} offer_type_list={this.state.offer_type_list} actionHandler={this.actions}/> : <div/>}
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable={false}
        pauseOnHover={false}
      />
      <div className="no-padding container-fixed-lg bg-white">
        <div className="container">
          <div className="card card-transparent">
            <div className="card-header p-l-0">
              <div className="card-title">
                <h4>Offers</h4>
              </div>
              <div className="card-controls">
                <ul>
                  <li><a href="#" className="card-close" data-toggle="close" onClick={()=>this.openQuickview(null)}><i className="pg-icon">plus</i></a></li>
                  <li><a href="#" className="card-refresh" title="Refresh" data-toggle="refresh" onClick={this.loadItems}><i className="card-icon card-icon-refresh"></i></a></li>

                </ul>
              </div>
            </div>
            <div className="card-body p-l-0 p-r-0 p-b-200">
              <div className="table-responsive" style={{minHeight:'550px'}}>
              {loading ?
                <div className="card-body">
                  <h4><Spinner color="primary"/> Loading...</h4>
                </div>
              :
                <BootstrapTable keyField='id' classes='table-condensed table table-striped table-hover table-bordered' remote={true} data={item_list} columns={columns} striped hover headerClasses="thead-light"/>
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
    )
  }
}

export default Offers;

// <li><a href="#" className="card-close" data-toggle="close" onClick={()=>this.openQuickview(null)}><i className="pg-icon">plus</i></a></li>
// <button className="btn" onClick={()=>this.openModal(row)} title="Employees"><i className="pg-icon">users</i></button>
// <a href="#" className="dropdown-item" onClick={()=>this.openHoursModal(row)} title="Available Hours"><i className="pg-icon">clock</i> Hours</a>
// <a href="#" className="dropdown-item" onClick={()=>this.openReservationModal(row)} title="Reservation Settings"><i className="pg-icon">settings</i> Res. Settings</a>
