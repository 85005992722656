import React, {Component} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import InputMask from 'react-input-mask';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class WYSIWYGBlock extends Component{
  constructor(props){
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
    }
  }

  componentDidMount(){
    let value = '';
    if (this.props.value){
      value = this.props.value;
    }
    const blocksFromHTML = convertFromHTML(value);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    this.setState({
      editorState: EditorState.createWithContent(state),
      contract:this.props.contract
    })
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState
    }, ()=>{this.props.handleInputChange(this.props.name, stateToHTML(editorState.getCurrentContent()))});
  }


  render(){
    const {editorState} = this.state;
    return (
      <div className="form-group form-group-default" style={{height:'400px'}}>
        <label>{this.props.title}</label>
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="form-control full-height"
          onEditorStateChange={this.onEditorStateChange}
        />
      </div>
    );
  }
}


class LocationLegal extends Component{
  constructor(props){
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.addItem(e);
  }

  render(){
    if (!this.props.location.hash_id) return <div>Loading</div>;
    const {location, backgroundClickHandler, handleInputChange} = this.props;
    return (
      <React.Fragment>
        <h3 className="m-t-0"><i className="pg-icon">format_textbox</i> Legal/Content</h3>
        <form role="form" id="form-register" onSubmit={this.handleSubmit}>
          <div className="form-group-attached">
            <div className="col-md-12">
              <WYSIWYGBlock name={'waitlist_message'} value={location.waitlist_message} handleInputChange={handleInputChange} title={'Waitlist Not Available Message'}/>
            </div>
            <div className="col-md-12">
              <WYSIWYGBlock name={'use_terms'} value={location.use_terms} handleInputChange={handleInputChange} title={'Use Terms'}/>
            </div>
            <div className="col-md-12">
              <WYSIWYGBlock name={'checkin_message'} value={location.checkin_message} handleInputChange={handleInputChange} title={'Check In Message'}/>
            </div>
            <div className="col-md-12">
              <WYSIWYGBlock name={'checkin_terms'} value={location.checkin_terms} handleInputChange={handleInputChange} title={'Check In Attestation'}/>
            </div>
          </div>
          <div className="row m-t-5">
            <div className="col-12">
              <div className="btn-group pull-right">
                <button className="btn btn-default" type="button" onClick={backgroundClickHandler}>Cancel</button>
                <button className="btn btn-primary" type="submit">Save</button>
              </div>
            </div>
          </div>
        </form>
    </React.Fragment>
    )
  }
}

export default LocationLegal;
