import React, { Component } from 'react';
import {Route} from "react-router-dom";
import Login from './Login';
import Register from './Register';

class LandingRoutes extends Component {
  render() {
    return (
    	<React.Fragment>
        <Route exact path="/" component={Login}/>
        <Route exact path="/login" component={Login}/>
        <Route exact path="/register" component={Register}/>
      </React.Fragment>
    )
  }
}

export default LandingRoutes;
// <Route exact path="/res/:locationCode" render={(props) => <WaitlistReserve {...props} currentState={1}/>}/>

// <Route exact path="/attendance-log" component={AttendanceLog}/>
// <Route exact path="/planning-tool" component={PlanningTool}/>
// <Route exact path="/production-plan" component={ProductionPlan}/>
// <Route exact path="/donor-human-stool" component={SubstanceLots}/>
// <Route exact path="/batches" component={Batches}/>
