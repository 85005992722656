import React, {Component, useCallback} from 'react';
import {inject} from 'mobx-react';
import Dropzone from 'react-dropzone';
import WYSIWYGBlock from '../common/WYSIWYGBlock';

@inject('userStore')
class OffersQuickview extends Component{
  constructor(props){
    super(props);
    this.state = {
      thumbEdit:true
    }
  }

  fileUpload = (files) => {
    this.props.fileUploadHandler(this.props.item.hash_id, files);
  }

  onDrop = (files) => {
    this.fileUpload(files)
  }

  render(){
    const {item, handleInputChange, handleInputChangeDirect, backgroundClickHandler, submitHandler, term_list, location_list, category_list, offer_type_list, actionHandler} = this.props;
    const isInvalid = item.title === '' && item.details === '' && item.business_term_id === null;
    const dropzoneStyles = {
        'position': 'relative',
        'width': '200px',
        'height': '200px',
        'borderWidth': '2px',
        'borderColor': 'rgb(102, 102, 102)',
        'borderStyle': 'dashed',
        'borderRadius': '5px',
        marginTop: '15px',
        marginRight: 'auto',
        marginBottom: 0,
        marginLeft: 'auto',
    };

    return (
      <div className="modal-content-wrapper">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="m-t-0"><i className="pg-icon">alert</i> Offer Information</h3>
          </div>
          <div className="modal-body">
            <form role="form" id="form-register">
              <div className="form-group-attached">
                <div className="row d-none">
                  <div className="col-md-12">
                    <div className="form-group form-group-default">
                      <React.Fragment>
                      <Dropzone onDrop={this.onDrop} style={dropzoneStyles} multiple={false}>
                        {({getRootProps, getInputProps}) => (
                          <section>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <p>click to select a jpeg file</p>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      <br/><button className="btn btn-xs" onClick={()=>this.setState({thumbEdit:false})}>Cancel</button>
                      </React.Fragment>
                    </div>
                  </div>
                </div>
                <div className={item.sys_category ? "row" : "row d-none"}>
                  <div className="col-md-12">
                    <img className="img-fluid" src={item.sys_category && item.sys_category.img ? item.sys_category.img : 'https://via.placeholder.com/750x500'} alt='Category Image'/>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group form-group-default">
                    <label className="p-b-10">Category</label>
                    {category_list.map((sys_category, sys_category_index)=>
                      <div key={sys_category_index}  className="form-check primary">
                        <input type="radio" name="sys_category_cb" id={sys_category_index} onChange={handleInputChange} value={sys_category.id} checked={item.category_id_list && item.category_id_list.includes(sys_category.id) ? 'checked' : null}/>
                        <label htmlFor={sys_category_index}>{sys_category.full_name}</label>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-group-default">
                      <label>Title</label>
                      <input type="text" name="title" placeholder="" className="form-control" value={item.title} onChange={handleInputChange} required/>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <WYSIWYGBlock name={'details'} value={item.details} handleInputChange={handleInputChangeDirect} title={'Offer Details'}/>
                </div>
                <div className="row">
                  <div className="form-group form-group-default">
                    <label className="p-b-10">Offer Type</label>
                    {offer_type_list.map((sys_offer_type, sys_offer_type_index)=>
                      <div key={sys_offer_type_index}  className="form-check primary">
                        <input type="radio" name="sys_offer_type_id" id={"ot_" + sys_offer_type_index} onChange={handleInputChange} value={sys_offer_type.id} checked={item.sys_offer_type_id === sys_offer_type.id ? 'checked' : null}/>
                        <label htmlFor={"ot_" + sys_offer_type_index}>{sys_offer_type.name}</label>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-group-default">
                      <label>Offer Value</label>
                      <input type="text" name="offer_value" placeholder="" className="form-control" value={item.offer_value} onChange={handleInputChange} required/>
                      <br/><small>The value of the offer e.g. 10%, $10, Buy one get one free, etc.</small>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-group-default">
                      <label>POS Code</label>
                      <input type="text" name="offer_code" placeholder="" className="form-control" value={item.offer_code} onChange={handleInputChange}/>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-group-default">
                      <label>Availability</label>
                      <input type="text" name="availability" placeholder="" className="form-control" value={item.availability} onChange={handleInputChange} required/>
                      <br/><small>When is the offer available?  e.g. now, between 1pm-2pm, until booked</small>
                    </div>
                  </div>
                </div>
                {1 === 0 ?
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-group-default">
                      <label>Expiration</label>
                      <input type="number" step="1" name="expiration_type" placeholder="" className="form-control" value={item.expiration_type} onChange={handleInputChange} required/>
                      <br/><small>e.g. Until Booked, </small>
                    </div>
                  </div>
                </div>
                : null}
                {1 === 0 ?
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-group-default">
                      <label>Quantity Available</label>
                      <input type="number" step="1" name="quantity_available" placeholder="" className="form-control" value={item.quantity_available} onChange={handleInputChange} required/>
                      <br/><small>For unlimited quantity enter 0</small>
                    </div>
                  </div>
                </div>
                : null}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group form-group-default">
                      <label>Location</label>
                      <select name="location_id" className="form-control"  onChange={handleInputChange} value={item.location_id}>
                        <option value=''>Select</option>
                      {location_list.map((location, location_index)=>
                        <option key={location_index} value={location.id}>{location.name}</option>
                      )}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group form-group-default">
                      <label>Terms</label>
                      <select name="business_term_id" className="form-control" onChange={handleInputChange} value={item.business_term_id}>
                        <option value=''>Select</option>
                      {term_list.map((term, term_index)=>
                        <option key={term_index} value={term.id}>{term.name}</option>
                      )}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-group-default">
                      <label>Offer URL</label>
                      <input type="text" name="offer_url" placeholder="" className="form-control" value={item.offer_url} onChange={handleInputChange}/>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group form-group-default">
                    <div className="form-check form-check-inline switch">
                      <input type="checkbox" name="is_published" id="checkStatus" checked={parseInt(item.is_published) === 1} onChange={handleInputChange}/>
                      <label htmlFor="checkStatus">
                        Make Viewable? {parseInt(item.is_published) === 1 ? <span className="badge badge-success">YES</span> : <span className="badge badge-danger">NO</span>}
                      </label>
                    </div>
                  </div>
                </div>
                {this.props.userStore.is_admin === 1 ?
                <div className="row">
                  <div className="form-group form-group-default">
                    <label>Image URL</label>
                    <input type="text" name="img_url" placeholder="" className="form-control" value={item.img_url} onChange={handleInputChange}/>
                  </div>
                </div>
                : null}
              </div>
            </form>
            <div className="row m-t-20">
              <div className="col-md-6 offset-md-6">
                <div className="btn-group pull-right">
                  <button aria-label="" type="button" className="btn btn-danger" onClick={backgroundClickHandler}>Cancel</button>
                  {item.id ?
                    <React.Fragment>
                    <button aria-label="" type="button" className={isInvalid ? "btn btn-default disabled" : "btn btn-default"} onClick={isInvalid ? ()=>{return false;} : ()=>actionHandler('publish')}>Publish Updates</button>
                    <button aria-label="" type="button" className={isInvalid ? "btn btn-default disabled" : "btn btn-default"} onClick={isInvalid ? ()=>{return false;} : ()=>actionHandler('notify')}>Send Nofitications</button>
                    </React.Fragment>
                  : null}
                  <button aria-label="" type="submit" className={isInvalid ? "btn btn-primary disabled" : "btn btn-primary"} onClick={isInvalid ? ()=>{return false;} : submitHandler}>Save</button>
                </div>
              </div>
            </div>
            <div className="row m-t-20">
              <div className="col-12">
                <span>Publish Updates will update the offer to the public.  This should be used if you have updated the offer details.  Send Notifications will send a message to subscribed users.  This should only be done once - sms fees will be applied based on the number of messages sent. {item.subscriber_count ? <span className="badge badge-info">Subscriber Count: {item.subscriber_count}</span> : null}</span>
              </div>
            </div>
          </div>
        </div>
    </div>
    )
  }
}

export default OffersQuickview;
