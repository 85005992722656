import React, {Component} from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class WYSIWYGBlock extends Component{
  constructor(props){
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
    }
  }

  componentDidMount(){
    let value = '';
    if (this.props.value){
      value = this.props.value;
    }
    this.setEditorState(value);
  }

  setEditorState = (value) => {
    let blocksFromHTML = convertFromHTML(value);
    let state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    this.setState({
      editorState: EditorState.createWithContent(state)
    })
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState
    }, ()=>{this.props.handleInputChange(this.props.name, stateToHTML(editorState.getCurrentContent()))});
  }

  render(){
    const {editorState} = this.state;
    return (
      <div className="form-group form-group-default" style={{height:'400px'}}>
        <label>{this.props.title}</label>
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="form-control full-height"
          onEditorStateChange={this.onEditorStateChange}
        />
      </div>
    );
  }
}
export default WYSIWYGBlock;
