import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { create } from 'mobx-persist';
import userStore from './store/userStore'
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import * as serviceWorker from './serviceWorker';
import axios from 'axios'

const hydrate = create({
    storage: localStorage,
    jsonify: true
})

// Add a 401 response interceptor
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.status) {
    userStore.logout();
    window.location.href = '/'; // redirect to log in screen
    return Promise.reject(error);
  } else {
    return Promise.reject(error);
  }
});

hydrate('UserStore', userStore)
  .then(()=>{
    var stores = {
      userStore
    };

    ReactDOM.render((
      <Provider {...stores}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    ), document.getElementById('root'));
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
