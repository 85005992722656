import React, {Component} from 'react';
import LocationsQuickview from './LocationsQuickview';
import LocationUserModal from './LocationUserModal';
import Modal from 'react-bootstrap4-modal';
import BootstrapTable from 'react-bootstrap-table-next';
import {Spinner} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
} from 'react-places-autocomplete';
import { connectionGet, connectionPost, connectionPut, connectionDelete } from '../utils/connection';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {withRouter} from "react-router-dom";
import { inject, observer } from 'mobx-react';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import moment from 'moment';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

@inject('userStore')
@observer
class LocationSettings extends Component{
  constructor(props){
    super(props);
    this.state = {
      subComponentShown:'info',
      loading:true,
      selectedItem:null,
      role_list:[],
      sys_share_type_list:[]
    }
  }

  componentDidMount(){
    this.loadItems();
  }

  componentWillReceiveProps(nextProps){
    this.loadItems();
  }

  loadItems = () => {
    this.setState({
      loading:true
    }, ()=>{
      connectionGet('business/' + this.props.userStore.business_hash_id + '/locations/' + this.props.match.params.locationHashId, {}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          this.setState({
            loading:false,
            role_list:res.data.role_list,
            selectedItem:res.data.location
          })
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    });
  }

  addSeating = (seating) => {
    if (seating.id){
      connectionPut('locations/' + this.state.selectedItem.hash_id + '/seating/' + seating.hash_id, {seating:seating}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({
            selectedItem:res.data.location
          })
          // this.loadItems()
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    } else {
      connectionPost('locations/' + this.state.selectedItem.hash_id + '/seating', {seating:seating}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({
            selectedItem:res.data.location
          })
          // this.loadItems()
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    }
  }

  addSeatingZone = (seating) => {
    if (seating.id){
      connectionPut('locations/' + this.state.selectedItem.hash_id + '/seating-zone/' + seating.hash_id, {seating:seating}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({
            selectedItem:res.data.location
          })
          // this.loadItems()
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    } else {
      connectionPost('locations/' + this.state.selectedItem.hash_id + '/seating-zone', {seating:seating}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({
            selectedItem:res.data.location
          })
          // this.loadItems()
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    }
  }

  addShare = (share) => {
    if (share.id){
      connectionPut('locations/' + this.state.selectedItem.hash_id + '/share/' + share.id, {share:share}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({
            selectedItem:res.data.location
          })
          // this.loadItems()
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    } else {
      connectionPost('locations/' + this.state.selectedItem.hash_id + '/share', {share:share}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({
            selectedItem:res.data.location
          })
          // this.loadItems()
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    }
  }

  removeReservation = (hours) => {
    if (hours.id){
      connectionDelete('locations/' + this.state.selectedItem.hash_id + '/reservation/' + hours.hash_id, {}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({
            selectedItem:res.data.location
          })
          // this.loadItems()
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    }
  }

  addReservation = (hours) => {
    if (hours.first_datetime && hours.last_datetime){
      hours.first_datetime = moment(hours.first_datetime).format('YYYY-MM-DD HH:mm:ss');
      hours.last_datetime = moment(hours.last_datetime).format('YYYY-MM-DD HH:mm:ss');
    }
    if (hours.id){
      connectionPut('locations/' + this.state.selectedItem.hash_id + '/reservation/' + hours.hash_id, {hours:hours}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({
            selectedItem:res.data.location
          })
          // this.loadItems();
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    } else {
      connectionPost('locations/' + this.state.selectedItem.hash_id + '/reservation', {hours:hours}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({
            selectedItem:res.data.location
          })
          // this.loadItems();
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    }
  }


  removeHours = (hours) => {
    if (hours.id){
      connectionDelete('locations/' + this.state.selectedItem.hash_id + '/hours/' + hours.hash_id, {}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({
            selectedItem:res.data.location
          })
          // this.loadItems()
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    }
  }

  addHours = (hours) => {
    if (hours.open_datetime && hours.closed_datetime){
      hours.open_datetime = moment(hours.open_datetime).format('YYYY-MM-DD HH:mm:ss');
      hours.closed_datetime = moment(hours.closed_datetime).format('YYYY-MM-DD HH:mm:ss');
    }
    if (hours.id){
      connectionPut('locations/' + this.state.selectedItem.hash_id + '/hours/' + hours.hash_id, {hours:hours}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({
            selectedItem:res.data.location
          })
          // this.loadItems();
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    } else {
      connectionPost('locations/' + this.state.selectedItem.hash_id + '/hours', {hours:hours}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({
            selectedItem:res.data.location
          })
          // this.loadItems();
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    }
  }

  addEmployee = (employee) => {
    if (employee.id){
      connectionPut('locations/' + this.state.selectedItem.hash_id + '/employees/' + employee.hash_id, {employee:employee}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({
            selectedItem:res.data.location
          })
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    } else {
      connectionPost('locations/' + this.state.selectedItem.hash_id + '/employees', {employee:employee}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({
            selectedItem:res.data.location
          })
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    }
  }

  addItem = (e) => {
    e.preventDefault();
    if (this.state.selectedItem.hash_id){
      let tmp = Object.assign({}, this.state.selectedItem);
      connectionPut('business/' + this.props.userStore.business_hash_id + '/locations/' + this.state.selectedItem.hash_id, {location:tmp}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({
            selectedItem:res.data.location
          })
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    } else {
      connectionPost('business/' + this.props.userStore.business_hash_id + '/locations', {location:this.state.selectedItem}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({
            selectedItem:res.data.location
          })
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    }
  }

  addUser = (user) => {
    if (user.id === 0 && user.user.email !== '' && user.user.password !== ''){
      connectionPost('locations/' + this.state.selectedItem.hash_id + '/users', {location_user:user}, {username: this.props.userStore.token}
      ).then((res) => {
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({
            selectedItem:res.data.location
          })
          // this.loadItems();
        } else {
          toast.error(res.data.message, toastParams);
        }
      }).catch((error) => {
        console.log(error);
        return error;
      });
    } else {
      // this is editing an existing user
      if (user.id !== 0){
        connectionPut('locations/' + this.state.selectedItem.hash_id + '/users/' + user.user.hash_id, {location_user:user}, {username: this.props.userStore.token}
        ).then((res) => {
          if (res.data.errors === 0){
            toast.success(res.data.message, toastParams);
            this.setState({
              selectedItem:res.data.location
            })
            // this.loadItems();
          } else {
            toast.error(res.data.message, toastParams);
          }
        }).catch((error) => {
          console.log(error);
          return error;
        });
      }
    }
  }

  handleInputChange = (e) => {
    let updated_obj = Object.assign({}, this.state.selectedItem);
    let value = e.target.value;
    if (e.target.type === 'checkbox'){
      if (e.target.checked){
        value = 1;
      } else {
        value = 0;
      }
    }
    if (e.target.name === 'ready_message'){
      if (value.length > 150){
        alert('Ready message must be less than 150 characters');
        return false;
      }
    }
    if (e.target.name === 'schedule_enabled' && e.target.checked){
      alert('Be sure to create a weekly schedule by clicking on the Hours Action.');
    }
    updated_obj[e.target.name] = value;
    this.setState({selectedItem:updated_obj});
  }

  handleAddressChange = (address) => {
    this.handleInputChangeDirect("name", address);
  }

  handleInputChangeDirect = (targetName, value) => {
    let updated_obj = Object.assign({}, this.state.selectedItem);
    updated_obj[targetName] = value;
    this.setState({selectedItem:updated_obj});
  }

  getPlace = placeId => {
    var service = new window.google.maps.places.PlacesService(document.createElement('div'));
    return new Promise((resolve, reject) => {
      service.getDetails({'placeId':placeId}, (results, status)=>{
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
          reject(status);
        }
        resolve(results);
      });
    });
  };

  selectSubComponent = (subComponent) => {
    this.setState({
      subComponentShown:subComponent
    })
  }

  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        this.getPlace(results[0].place_id).then(results => {
          this.handleInputChangeDirect("name", results.name);
          this.handleInputChangeDirect("g_place_id", results.place_id);
          this.handleInputChangeDirect("lat", results.geometry.location.lat());
          this.handleInputChangeDirect("lng", results.geometry.location.lng());
          this.handleInputChangeDirect("phone", results.formatted_phone_number);
          this.handleInputChangeDirect("website", results.website);
          for (var i = 0; i < results.address_components.length; i++) {
            var addressType = results.address_components[i].types[0];
            if (addressType == 'street_number'){
              this.handleInputChangeDirect("address1", results.address_components[i].long_name);

              // this.formApi.setValue("address", results.address_components[i].long_name);
            }
            if (addressType == 'route'){
              this.handleInputChangeDirect("address1", this.state.selectedItem.address1 + " " + results.address_components[i].short_name);
              // this.formApi.setValue("address", this.formApi.getValue("address") + ' ' + results.address_components[i].short_name);
            }
            if (addressType == 'locality'){
              this.handleInputChangeDirect("city", results.address_components[i].long_name);
              // this.formApi.setValue("city", results.address_components[i].long_name);
            }
            if (addressType == 'administrative_area_level_1'){
              this.handleInputChangeDirect("region", results.address_components[i].short_name);
              // var state = results.address_components[i].short_name
              // this.formApi.setValue("state", state);
            }
            if (addressType == 'postal_code'){
              this.handleInputChangeDirect("postal", results.address_components[i].short_name);
              // this.formApi.setValue("zipcode", results.address_components[i].short_name);
              // this.setState({
              //     errors:this.formApi.getState().errors
              // })
            }
          }
        });
      });
        // .then(latLng => s('Success', latLng))
        // .catch(error => console.error('Error', error));
  };

  render(){
    const {loading, selectedItem, role_list, sys_share_type_list, subComponentShown} = this.state;
    return (
      <React.Fragment>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable={false}
        pauseOnHover={false}
      />
      <div className="no-padding container-fixed-lg bg-white">
        <div className="container">
          <div className="card col-12">
          {loading ?
            <div className="card-header">
              <h3><Spinner color="primary"/> Loading Settings...</h3>
            </div>
          :
          <React.Fragment>
            <div className="card-header">
              <h3><i className="pg-icon">settings</i> {selectedItem.name} - Settings</h3>
            </div>
            <div className="card-body">
              <div className="card card-transparent flex-row">
                <ul className="nav nav-tabs nav-tabs-simple nav-tabs-left bg-white" id="tab-3">
                  <li className="nav-item">
                    <a href="#" className={subComponentShown === 'info' ? "active" : null} onClick={()=>this.selectSubComponent('info')} data-toggle="tab" data-target="#information"><i className="pg-icon">alert</i> Information</a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className={subComponentShown === 'staff' ? "active" : null}  onClick={()=>this.selectSubComponent('staff')} data-toggle="tab" data-target="#staff"><i className="pg-icon">users</i> Users</a>
                  </li>
                </ul>
                <div className="tab-content bg-white full-height">
                  <div className={subComponentShown === 'info' ? "tab-pane active full-height" : "tab-pane"} id="information">
                    <PerfectScrollbar>
                      <LocationsQuickview backgroundClickHandler={this.backgroundClick} handleInputChange={this.handleInputChange} handleSelect={this.handleSelect} handleAddressChange={this.handleAddressChange} item={selectedItem} submitHandler={this.addItem}/>
                    </PerfectScrollbar>
                  </div>
                  <div className={subComponentShown === 'staff' ? "tab-pane active" : "tab-pane"} id="staff">
                    <LocationUserModal backgroundClickHandler={this.backgroundClick} location={selectedItem} addUser={this.addUser} role_list={role_list}/>
                  </div>
                </div>
              </div>
            </div>
            </React.Fragment>
            }
          </div>
        </div>
      </div>
    </React.Fragment>
    )
  }
}

export default LocationSettings;

// <li><a href="#" className="card-close" data-toggle="close" onClick={()=>this.openQuickview(null)}><i className="pg-icon">plus</i></a></li>
// <button className="btn" onClick={()=>this.openModal(row)} title="Employees"><i className="pg-icon">users</i></button>
// <a href="#" className="dropdown-item" onClick={()=>this.openHoursModal(row)} title="Available Hours"><i className="pg-icon">clock</i> Hours</a>
// <a href="#" className="dropdown-item" onClick={()=>this.openReservationModal(row)} title="Reservation Settings"><i className="pg-icon">settings</i> Res. Settings</a>
