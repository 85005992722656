import React, {Component} from 'react';
import TermsQuickview from './TermsQuickview';
import Modal from 'react-bootstrap4-modal';
import BootstrapTable from 'react-bootstrap-table-next';
import {Spinner} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { connectionGet, connectionPost, connectionPut, connectionDelete } from '../utils/connection';
import {withRouter} from "react-router-dom";
import { inject, observer } from 'mobx-react';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import moment from 'moment';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

@inject('userStore')
@observer
class Terms extends Component{
  constructor(props){
    super(props);
    this.state = {
      quickviewVisible:false,
      loading:true,
      item_list:[],
      quickview:null,
      selectedItem:{id:null, name:'', terms:'', versioning:''},
    }
  }

  componentDidMount(){
    this.loadItems();
  }

  loadItems = () => {
    this.setState({
      loading:true
    }, ()=>{
      connectionGet('business/' + this.props.userStore.business_hash_id + '/terms', {}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          this.setState({
            loading:false,
            item_list:res.data.item_list
          })
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    });
  }

  addItem = (e) => {
    e.preventDefault();
    if (this.state.selectedItem.hash_id){
      let tmp = Object.assign({}, this.state.selectedItem);
      connectionPut('business/' + this.props.userStore.business_hash_id + '/terms/' + this.state.selectedItem.hash_id, {term:tmp}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({
            item_list:res.data.item_list,
            selectedItem:{id:null, name:'', terms:'', versioning:''}
          },()=>this.backgroundClick())
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    } else {
      connectionPost('business/' + this.props.userStore.business_hash_id + '/terms', {term:this.state.selectedItem}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({
            item_list:res.data.item_list,
            selectedItem:{id:null, name:'', terms:'', versioning:''}
          },()=>this.backgroundClick())
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    }
  }

  backgroundClick = () => {
    this.setState({
      quickviewVisible:false,
      quickview:null,
      selectedItem:null
    })
  }

  handleInputChange = (e) => {
    let updated_obj = Object.assign({}, this.state.selectedItem);
    let value = e.target.value;
    if (e.target.type === 'checkbox'){
      if (e.target.checked){
        value = 1;
      } else {
        value = 0;
      }
    }
    updated_obj[e.target.name] = value;
    this.setState({selectedItem:updated_obj});
  }

  handleInputChangeDirect = (targetName, value) => {
    let updated_obj = Object.assign({}, this.state.selectedItem);
    updated_obj[targetName] = value;
    this.setState({selectedItem:updated_obj});
  }

  openQuickview = (term) => {
    this.backgroundClick();
    if (term === null){
      term = {id:null, name:'', terms:'', versioning:''}
    }
    this.setState({
      quickviewVisible:true,
      selectedItem:term,
      quickview:<TermsQuickview backgroundClickHandler={this.backgroundClick} handleInputChange={this.handleInputChange} handleInputChangeDirect={this.handleInputChangeDirect} item={term} submitHandler={this.addItem}/>
    })
  }

  renderHtml = (content) => {
    return {__html: content};
  }

  render(){
    const {loading, selectedItem, item_list, quickviewVisible} = this.state;
    const columns = [{
      dataField: 'name',
      text: 'Name',
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div className="text-truncate">
            {row.name}
          </div>
        );
      }
    }, {
      dataField: 'terms',
      text: 'Legal Terms',
      isDummyField:true,
      headerClasses: 'd-none d-sm-table-cell',
      classes: 'd-none d-sm-table-cell',
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div className="text-truncate" dangerouslySetInnerHTML={this.renderHtml(row.terms)}/>
        );
      }
    },{
      dataField: 'Actions',
      isDummyField: true,
      text: 'Actions',
      formatter: (cellContent, row) => {
        return (
          <div className="dropdown dropdown-default full-width">
            <button aria-label="" className="btn dropdown-toggle full-width text-truncate d-inline-block" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span className="semi-bold">Actions</span>
            </button>
            <div className="dropdown-menu">
              <a onClick={()=>this.openQuickview(row)} className="dropdown-item" title="Edit"><i className="pg-icon">pencil</i> Edit</a>
            </div>
          </div>
        );
      }
    }];
    return (
      <React.Fragment>
      <Modal visible={quickviewVisible} className="modal slide-right" dialogClassName="modal-dialog" fade={true} onClickBackdrop={this.backgroundClick}>
        {this.state.quickview ? this.state.quickview : <div/>}
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable={false}
        pauseOnHover={false}
      />
      <div className="no-padding container-fixed-lg bg-white">
        <div className="container">
          <div className="card card-transparent">
            <div className="card-header p-l-0">
              <div className="card-title">
                <h4>Terms</h4>
              </div>
              <div className="card-controls">
                <ul>
                  {this.props.userStore.is_admin ?
                    <li><a href="#" className="card-close" data-toggle="close" onClick={()=>this.openQuickview(null)}><i className="pg-icon">plus</i></a></li>
                  :null}
                  <li><a href="#" className="card-refresh" title="Refresh" data-toggle="refresh" onClick={this.loadItems}><i className="card-icon card-icon-refresh"></i></a></li>

                </ul>
              </div>
            </div>
            <div className="card-body p-l-0 p-r-0 p-b-200">
              <div className="table-responsive" style={{minHeight:'550px'}}>
              {loading ?
                <div className="card-body">
                  <h4><Spinner color="primary"/> Loading...</h4>
                </div>
              :
                <BootstrapTable keyField='id' classes='table-condensed table table-striped table-hover table-bordered' remote={true} data={item_list} columns={columns} striped hover headerClasses="thead-light"/>
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
    )
  }
}

export default Terms;

// <li><a href="#" className="card-close" data-toggle="close" onClick={()=>this.openQuickview(null)}><i className="pg-icon">plus</i></a></li>
// <button className="btn" onClick={()=>this.openModal(row)} title="Employees"><i className="pg-icon">users</i></button>
// <a href="#" className="dropdown-item" onClick={()=>this.openHoursModal(row)} title="Available Hours"><i className="pg-icon">clock</i> Hours</a>
// <a href="#" className="dropdown-item" onClick={()=>this.openReservationModal(row)} title="Reservation Settings"><i className="pg-icon">settings</i> Res. Settings</a>
