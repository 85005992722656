import { observable, computed, action } from "mobx";
import { connectionPost } from '../utils/connection';
import { create, persist } from 'mobx-persist';
import { auth } from '../firebase';

class UserStore {
    @persist @observable modalVisible = false;
    @persist @observable first_name = '';
    @persist @observable last_name = '';
    @persist @observable name = '';
    @persist @observable phone_number = '';
    @persist @observable email = '';
    @persist @observable firebase_uid = '';
    @persist @observable hash_id = '';
    @persist @observable token = '';
    @persist @observable id = 0;
    @persist @observable is_admin = 0;
    @persist @observable business_hash_id = '';
    @persist('object') @observable business = {};
    @persist('object') @observable user_role_list = [];
    @persist('object') @observable bus_user_role_id_list = [];
    @persist('object') @observable location_user_list = [];

    @computed get is_authenticated() {
    	if (this.token !== ''){
    		return this.token;
    	} else {
    		return false;
    	}
    }

    @action get_token(fire_uid){
      return connectionPost('token', {}, {username: fire_uid, password: ''}
			).then((res) => {
        if (res.data.token){
          // should return: user, order list, favorites,
          this.business_hash_id = res.data.user.business_hash_id;
          this.token = res.data.token; // set token last as this is what triggers user to different view
          this.first_name = res.data.user.first_name;
          this.last_name = res.data.user.last_name;
          this.name = res.data.user.full_name;
          this.email = res.data.user.email;
          this.phone_number = res.data.user.phone_number;
          this.firebase_uid = res.data.user.firebase_uid;
          this.hash_id = res.data.user.hash_id;
          this.id = res.data.user.id;
          this.is_admin = res.data.user.is_admin;
          this.business = res.data.user.business;
          this.user_role_list = res.data.user.user_role_list;
          this.primary_role = res.data.user.primary_role;
          // this.bus_user_role_id_list = res.data.user.bus_user_role_id_list;
          // this.location_user_list = res.data.user.location_user_list;
          // this.user_role_list = res.data.user.user_role_list;
          // this.bus_user_role_id_list = res.data.user.bus_user_role_id_list;
          // this.location_user_list = res.data.user.location_user_list;
        }
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
    }

    @action setToken(newtoken){
      this.token = newtoken;
    }

    @action logout(){
      auth.doSignOut().then(()=>{
        this.modalVisible = false;
        this.first_name = '';
        this.last_name = '';
        this.name = '';
        this.email = '';
        this.phone_number = '';
        this.firebase_uid = '';
        this.id = 0;
        this.token = '';
        this.hash_id = '';
        this.is_admin = 0;
        this.business_hash_id = '';
        this.business = {};
        this.user_role_list = [];
        this.bus_user_role_id_list = [];
        this.location_user_list = [];
      });
    }
}

export default new UserStore();
