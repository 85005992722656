import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { connectionPost} from '../utils/connection';
import {Redirect} from 'react-router-dom';
import { auth } from '../firebase';
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
} from 'react-places-autocomplete';
import 'react-toastify/dist/ReactToastify.css';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

class Register extends Component {
  constructor(props){
    super(props);
    this.state = {
      redirect:false,
      register:{id:null, first_name:'', last_name:'', email:'', password:'', password_verify:'', is_business:1, has_multiple:0, name:'', address1:'', address2:'', city:'', region:'', postal:'', website:'', phone:'', email:'', lat:'', lng:'', g_place_id:''}
    }
  }

  register = (e) => {
    e.preventDefault();
    if (this.state.register.g_place_id !== ''){
      // perform firebase user creation step
      auth.doCreateUserWithEmailAndPassword(this.state.register.email, this.state.register.password)
        .then((user) => {
          connectionPost('register', {register:this.state.register, fb_user:user.user}, {}
          ).then((res) => {
            if (res.data.errors === 0){
              toast.success(res.data.message, toastParams);
              this.setState({
                register:{id:null, first_name:'', last_name:'', email:'', password:'', password_verify:'', is_business:1, has_multiple:0, name:'', address1:'', address2:'', city:'', region:'', postal:'', website:'', phone:'', email:'', lat:'', lng:'', g_place_id:''}
              },()=>{this.setState({redirect:true})})
            } else {
              toast.error(res.data.message, toastParams);
            }
          }).catch((error) => {
            console.log(error);
            return error;
          });
        }).catch(error => {
          return error
        });
    } else {
      alert('You must select a Business from the list');
    }
  }

  handleInputChange = (e) => {
    let updated_obj = Object.assign({}, this.state.register);
    let value = e.target.value;
    if (e.target.type === 'radio'){
      if (e.target.checked){
        value = parseInt(e.target.value);
      }
    }
    if (e.target.type === 'checkbox'){
      if (e.target.checked){
        value = 1;
      } else {
        value = 0;
      }
    }
    updated_obj[e.target.name] = value;
    this.setState({register:updated_obj});
  }

  handleAddressChange = (address) => {
    this.handleInputChangeDirect("name", address);
  }

  handleInputChangeDirect = (targetName, value) => {
    let updated_obj = Object.assign({}, this.state.register);
    updated_obj[targetName] = value;
    this.setState({register:updated_obj});
  }

  getPlace = placeId => {
    var service = new window.google.maps.places.PlacesService(document.createElement('div'));
    return new Promise((resolve, reject) => {
      service.getDetails({'placeId':placeId}, (results, status)=>{
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
          reject(status);
        }
        resolve(results);
      });
    });
  };

  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        this.getPlace(results[0].place_id).then(results => {
          console.log(results);
          this.handleInputChangeDirect("name", results.name);
          this.handleInputChangeDirect("g_place_id", results.place_id);
          this.handleInputChangeDirect("lat", results.geometry.location.lat());
          this.handleInputChangeDirect("lng", results.geometry.location.lng());
          this.handleInputChangeDirect("phone", results.formatted_phone_number);
          this.handleInputChangeDirect("website", results.website);
          for (var i = 0; i < results.address_components.length; i++) {
            var addressType = results.address_components[i].types[0];
            if (addressType == 'street_number'){
              this.handleInputChangeDirect("address1", results.address_components[i].long_name);

              // this.formApi.setValue("address", results.address_components[i].long_name);
            }
            if (addressType == 'route'){
              this.handleInputChangeDirect("address1", this.state.register.address1 + " " + results.address_components[i].short_name);
              // this.formApi.setValue("address", this.formApi.getValue("address") + ' ' + results.address_components[i].short_name);
            }
            if (addressType == 'locality'){
              this.handleInputChangeDirect("city", results.address_components[i].long_name);
              // this.formApi.setValue("city", results.address_components[i].long_name);
            }
            if (addressType == 'administrative_area_level_1'){
              this.handleInputChangeDirect("region", results.address_components[i].short_name);
              // var state = results.address_components[i].short_name
              // this.formApi.setValue("state", state);
            }
            if (addressType == 'postal_code'){
              this.handleInputChangeDirect("postal", results.address_components[i].short_name);
              // this.formApi.setValue("zipcode", results.address_components[i].short_name);
              // this.setState({
              //     errors:this.formApi.getState().errors
              // })
            }
          }
        });
      });
        // .then(latLng => console.log('Success', latLng))
        // .catch(error => console.error('Error', error));
  };


  render() {
    document.getElementById('root').classList.add('full-height');
    const {register} = this.state;
    if (this.state.redirect){
      return <Redirect to={'/'}/>;
    }
    return (
        <React.Fragment>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="register-container full-height sm-p-t-30">
            <div className="d-flex justify-content-center flex-column full-height ">
              <img src={require('../img/logo_dark.png')} alt="logo" data-src={require('../img/logo_dark.png')} width="130"/>
              <h3>What's the Deal?</h3>
              <p>
                Create an instty.com account or <a href="/">Sign In</a> if you already have an account.
              </p>
              <form id="form-register" className="p-t-15" role="form" method="post" autocomplete={'off'}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group form-group-default">
                      <label>First Name</label>
                      <input type="text" name="first_name" placeholder="John" className="form-control" value={register.first_name} onChange={this.handleInputChange} required/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group form-group-default">
                      <label>Last Name</label>
                      <input type="text" name="last_name" placeholder="Smith" className="form-control" value={register.last_name} onChange={this.handleInputChange} required/>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-group-default">
                      <label>Email</label>
                      <input type="email" name="email" placeholder="You will use this to log in" className="form-control" value={register.email} onChange={this.handleInputChange} required/>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group form-group-default">
                      <label>Password</label>
                      <input type="password" name="password" placeholder="Minimum of 8 Charactors" className="form-control" value={register.password} onChange={this.handleInputChange} required/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group form-group-default">
                      <label>Verify Password</label>
                      <input type="password" name="password_verify" placeholder="Minimum of 8 Charactors" className="form-control" value={register.password_verify} onChange={this.handleInputChange} required/>
                    </div>
                  </div>
                </div>
                {register.is_business === 1 ?
                <React.Fragment>
                <div className="row m-t-10">
                  <div className="col-lg-12">
                    <div className="form-check checkbox-circle complete">
                      <input type="checkbox" name="has_multiple" checked={register.has_multiple === 1} id="has_multiple" value={1} onChange={this.handleInputChange}/>
                      <label for="has_multiple">
                        I own/manage multiple locations. <small className="hint-text d-block">Selecting this option means your business headquarters is different from the location(s) patrons visit.</small>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-group-default">
                      <label>Business Name</label>
                      <PlacesAutocomplete value={register.name} onChange={this.handleAddressChange} onSelect={this.handleSelect}>
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <React.Fragment>
                          <input
                              {...getInputProps({
                                placeholder: 'Enter Business Name',
                                className: 'form-control',
                              })}
                          />
                          <div className={suggestions.length > 0 ? "autocomplete-dropdown-container" : null}>
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                              return (
                                <div style={{ borderWidth: 0.5, borderColor: '#d6d7da'}}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </React.Fragment>
                      )}
                      </PlacesAutocomplete>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group form-group-default">
                      <label>Address 1</label>
                      <input type="text" name="address1" placeholder="" className="form-control" value={register.address1} onChange={this.handleInputChange} required/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group form-group-default">
                      <label>Address 2</label>
                      <input type="text" name="address2" placeholder="" className="form-control" value={register.address2} onChange={this.handleInputChange}/>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group form-group-default">
                      <label>City</label>
                      <input type="text" name="city" placeholder="" className="form-control" value={register.city} onChange={this.handleInputChange} required/>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group form-group-default">
                      <label>State</label>
                      <input type="text" name="region" placeholder="2 Letter" className="form-control" value={register.region} onChange={this.handleInputChange} required/>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group form-group-default">
                      <label>Postal Code</label>
                      <input type="text" name="postal" placeholder="Zipcode" className="form-control" value={register.postal} onChange={this.handleInputChange} required/>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group form-group-default">
                      <label>Website</label>
                      <input type="text" name="website" placeholder="Website URL" className="form-control" value={register.website} onChange={this.handleInputChange} required/>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group form-group-default">
                      <label>Phone</label>
                      <input type="text" name="phone" placeholder="Primary Contact" className="form-control" value={register.phone} onChange={this.handleInputChange} required/>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group form-group-default">
                      <label>Email</label>
                      <input type="text" name="email" placeholder="Email" className="form-control" value={register.email} onChange={this.handleInputChange} required/>
                    </div>
                  </div>
                </div>
                </React.Fragment>
                : null}
                <div className="row">
                  <div className="col-lg-6">
                    <p><small>I agree to the <a href="#" className="text-info">instty.com Terms</a> and <a href="#" className="text-info">Privacy</a>.</small></p>
                  </div>
                  <div className="col-lg-6 text-right">
                    <a href="mailto:andrew@instty.com" className="text-info small">Help? Contact Support</a>
                  </div>
                </div>
                <button aria-label="" className="btn btn-primary btn-cons m-t-10" type="submit" onClick={this.register}>Create a new account</button>
              </form>
            </div>
          </div>
        </React.Fragment>
      );
  }
}

export default Register;
