import React, {Component} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import InputMask from 'react-input-mask';

class LocationUserModal extends Component{
  constructor(props){
    super(props);
    var generator = require('generate-password');
    var password = generator.generate({
        length: 10,
        numbers: true
    });
    this.state = {
      loading:true,
      user:{id:0, user:{first_name:'', last_name:'', email:'', password:password, sys_status_id:1}, role_id_list:[], role_list:[]}
    }
  }

  handleInputChange = (e) => {
    let updated_user = Object.assign({}, this.state.user);
    // const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const value = e.target.value;
    if (e.target.name === 'role'){
      let bIncluded = updated_user.role_id_list.includes(parseInt(value));
      if (e.target.checked){
        // add if not included
        if (!bIncluded){
          updated_user.role_id_list.push(parseInt(value));
        }
      } else {
        // remove if included
        var index = updated_user.role_id_list.indexOf(parseInt(value));
        if (index !== -1) updated_user.role_id_list.splice(index, 1);
      }
    } else if (e.target.name !== 'sys_status_id'){
      updated_user['user'][e.target.name] = value;
    } else {
      updated_user[e.target.name] = value;
    }
    this.setState({user:updated_user});
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.addUser(this.state.user);
    var generator = require('generate-password');
    var password = generator.generate({
        length: 10,
        numbers: true
    });
    this.setState({
      user:{id:0, user:{first_name:'', last_name:'', email:'', password:password, sys_status_id:1}, role_id_list:[], role_list:[]}
    })
  }

  editUser = (user) => {
    let tmp_user = Object.assign({}, this.state.user);
    tmp_user = user;
    tmp_user.user.password = '';
    this.setState({
      user:tmp_user
    })
  }

  render(){
    if (!this.props.location.hash_id) return <div>Loading</div>;
    const {location, backgroundClickHandler, role_list} = this.props;
    const {user} = this.state;
    const isInvalid = user.user.email === '' && user.user.first_name === '' && user.user.last_name === '' && user.user.password === '';
    const columns = [{
      dataField: 'name',
      text: 'Name',
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div className="text-truncate">
            {row.name}
          </div>
        );
      }
    }, {
      dataField: 'email',
      text: 'Email',
      headerClasses: 'd-none d-sm-table-cell',
      classes: 'd-none d-sm-table-cell',
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div className="text-truncate">
            {row.email}
          </div>
        );
      }
    }, {
      dataField: 'sys_status_id',
      text: 'Status',
      headerClasses: 'd-none d-sm-table-cell',
      classes: 'd-none d-sm-table-cell',
      sort: false,
      formatter: (cellContent, row) => {
        if (row.sys_status_id === 1) {
          return (
            <span className="badge badge-success">{row.sys_status}</span>
          )
        } else if (row.sys_status_id === 2) {
          return (
            <span className="badge badge-danger">{row.sys_status}</span>
          )
        }
      }
    }, {
      dataField: 'user_role_list',
      text: 'Roles',
      headerClasses: 'd-none d-sm-table-cell',
      classes: 'd-none d-sm-table-cell',
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <ul>
          {row.role_list.map((role, role_index)=>
            <li>{role.sys_user_role}</li>
          )}
          </ul>
        )
      }
    },{
      dataField: 'Actions',
      isDummyField: true,
      text: 'Actions',
      formatter: (cellContent, row) => {
        return (
          <button className="btn btn-primary" onClick={()=>this.editUser(row)}>Edit</button>
        );
      }
    }];
    return (
      <React.Fragment>
        <h3 className="m-t-0"><i className="pg-icon">users</i> Staff</h3>
              <form role="form" id="form-location-user" onSubmit={this.handleSubmit}>
                <div className="form-group-attached">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group form-group-default">
                        <label>Email</label>
                        <input type="text" className="form-control"  value={user.user.email} name="email" onChange={this.handleInputChange} required/>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group form-group-default">
                        <label>First Name</label>
                        <input type="text" name="first_name" placeholder="" className="form-control" required value={user.user.first_name} onChange={this.handleInputChange}/>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group form-group-default">
                        <label>Last Name</label>
                        <input type="text" name="last_name" placeholder="" className="form-control" value={user.user.last_name} onChange={this.handleInputChange}/>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group form-group-default">
                        <label>Password</label>
                        <input type="text" name="password" placeholder="Leave blank if not changing" className="form-control" value={user.user.password} onChange={this.handleInputChange}/>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group form-group-default">
                        <label>Roles</label>
                        {role_list.map((role, role_index)=>
                          <div key={role_index}  className="form-check primary">
                            <input type="checkbox" name="role" id={role_index} onChange={this.handleInputChange} value={role.id} checked={user.role_id_list && user.role_id_list.includes(role.id) ? 'checked' : null}/>
                            <label htmlFor={role_index}>{role.name}</label>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group form-group-default">
                        <label>Status</label>
                        <select name="sys_status_id" className="form-control" value={parseInt(user.sys_status_id)} onChange={this.handleInputChange}>
                          <option value={1}>ACTIVE</option>
                          <option value={2}>INACTIVE</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-t-5">
                  <div className="col-12">
                    <div className="btn-group pull-right">
                      <button className="btn btn-default" type="button">Cancel</button>
                      <button className={isInvalid ? "btn btn-primary disabled" : "btn btn-primary"} type="submit">Save</button>
                    </div>
                  </div>
                </div>
              </form>
              <div className="table-responsive">
                <BootstrapTable keyField='id' classes='table-condensed table table-striped table-hover table-bordered' data={location.location_user_list} columns={columns} striped hover headerClasses="thead-light"/>
              </div>
    </React.Fragment>
    )
  }
}

export default LocationUserModal;
